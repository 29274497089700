import * as React from "react";
import {useContext} from "react";
import {Button, Card, Icon} from "@salesforce/design-system-react";
import {Form, Formik, insert} from "formik";
import {
    FormActions,
    SldsFormElementCompound,
    SldsFormElementRow,
    SldsInput,
    SldsInputField,
    SldsSelectField,
    SldsTextarea
} from "../../common/ui/form/formElements";
import gql from "graphql-tag";
import * as log from "../../common/log";
import {Log} from "../../common/log";
import {useMutation, useQuery} from "@apollo/client";
import {AppContext} from "../../app/appPage";
import {useGraphqlLoadingComponent} from "../../common/graphql";
import {decodeToken, useAuthContext} from "../../common/context/authContext";
import {PillContainerField} from "../../common/ui/form/pillContainerField";
import Lookup from "../../common/ui/lookup/lookup";
import ApiDocumentation from "./apiDocumentation";
import {useT} from "../../common/i18n";
import {useNotificationContext} from "../../notifications/notificationContext";
import {FeatureContext} from "../../common/context/featureContext";
import {FeatureNotEnabled} from "../../common/featureNotEnabled";

// TODO: Limit suggestions to roles usable by enabled features
const ROLES = [
    {
        name: "network-server",
        displayName: "Network Server",
    },
    {
        name: "org-admin",
        displayName: "Organisation Admin",
    },
    {
        name: "device-admin",
        displayName: "Device Admin",
    },
    {
        name:  "lansen-mqtt-input",
        displayName: "Lansen Mqtt"
    },
    {
        name:  "lobaro-mqtt-input",
        displayName: "Lobaro Mqtt"
    },
    {
        name: "suborg",
        displayName: "Read-only access to sub-organisations"
    }
];

function getRole(name) {
    return ROLES.find(it => it.name === name) || {name: name, displayName: name + "*"};
}

const MUTATION_CREATE_API_TOKEN = gql`
    mutation createToken($apiToken: ApiTokenInput!) {
        createApiToken(input: $apiToken) {
            id
            name
            token
            organisationId
        }
    }
`;


const MUTATION_DELETE_API_TOKEN = gql`
    mutation createToken($tokenId: ID!) {
        deleteApiToken(id: $tokenId) {
            id
            name
            token
        }
    }
`;

const QUERY_API_TOKENS = gql`
    query apiTokens($appId: ID, $orgId: ID) {
        apiTokenList(appId: $appId, organisationId: $orgId) {
            id
            appId
            organisationId
            name
            token
            password
        }
    }
`;

const refetchApiTokens = (orgId, appId) => ({
    query: QUERY_API_TOKENS,
    variables: {
        appId: appId,
        orgId: orgId,
    }
});


export default function RestApiTab() {
    const license = useContext(FeatureContext)
    if (!(license.validateFeatures("lobaro-device-gateway") || license.validateFeatures("rest-api") || license.validateFeatures("wmbus-api"))) {
        return <FeatureNotEnabled/>
    }
    const t = useT();
    const app = useContext(AppContext);
    const auth = useAuthContext();
    const orgId = auth.organisationId();
    const notify = useNotificationContext();

    const apiTokensResult = useQuery(QUERY_API_TOKENS, {
        variables: {
            orgId: orgId,
            appId: app.id,
        }
    });

    const [createTokenMutation] = useMutation(MUTATION_CREATE_API_TOKEN, {
        refetchQueries: [refetchApiTokens(orgId, app.id)],
    });
    const [deleteTokenMutation] = useMutation(MUTATION_DELETE_API_TOKEN, {
        refetchQueries: [refetchApiTokens(orgId, app.id)],
    });

    const loading = useGraphqlLoadingComponent(apiTokensResult);
    if (loading) {
        return loading;
    }

    const {data} = apiTokensResult;
    let apiTokens = data.apiTokenList;
    apiTokens = apiTokens.map((tok) => {
        return {
            ...tok,
            tokenParsed: decodeToken(tok.token),
        };
    });


    function deleteToken(tok) {

        if (!window.confirm(t("integrations.rest.confirm.delete-token", "Delete API Token '{{name}}' with id {{id}}?", {
            name: tok.name,
            id: tok.id
        }))) {
            return;
        }

        deleteTokenMutation({
            variables: {
                tokenId: tok.id
            }
        }).catch((err) => {
            log.Error("Failed to delete api token:", err);
            notify.error(t("integrations.rest.notify.delete-token-failed", "Failed to delete api token."));
        });
    }


    return <div className="slds-m-horizontal--x-small">
        <ApiDocumentation/>
        <div className="slds-text-heading--medium">{t("integrations.rest.api-credentials", "API Credentials")}</div>
        {
            apiTokens.map((tok) => {
                return <Card key={tok.id}
                             heading={tok.name}
                             className="slds-card_boundary slds-p-horizontal--x-small"
                             icon={<Icon category="standard" name="data_integration_hub" size="small"/>}
                             headerActions={<div><Button variant="destructive"
                                                         onClick={() => deleteToken(tok)}>{t("common.button.delete", "Delete")}</Button>
                             </div>}
                >
                    { // When a password is set, show only BasicAuth credentials, else show the token
                        tok.password ?
                            <div className="slds-m-bottom--x-small">
                                <SldsFormElementCompound label={t("integrations.rest.basic-auth", "BasicAuth")}>
                                    <SldsFormElementRow>
                                        <SldsInput label={t("integrations.rest.username", "Username")}
                                                   field={{value: "token-" + tok.id}}/>
                                        <SldsInput label={t("integrations.rest.password", "Password")}
                                                   field={{value: tok.password}}/>
                                        <SldsInput label={t("integrations.rest.basic-auth-header", "BasicAuth Header")}
                                                   field={{value: "Basic " + btoa("token-" + tok.id + ":" + tok.password)}}/>
                                    </SldsFormElementRow>
                                </SldsFormElementCompound>
                            </div>
                            :
                            <SldsTextarea label={t("integrations.rest.auth-header", "Authorization Header")} rows={3}
                                          field={{value: "Bearer " + tok.token}}/>
                    }

                    <Formik
                        initialValues={{...tok, "roles": tok.tokenParsed.roles}}
                        render={() => {
                            return <PillContainerField pillLabelExtractor={(r) => r} name={"roles"}
                                                    tooltipExtractor={(r) => getRole(r)?.displayName || r}
                                                    label={t("integrations.rest.roles", "Roles")} readOnly={true}/>;
                        }}
                    />


                    {/*<Json json={t.tokenParsed}/>*/}
                </Card>;
            })
        }
        <Card
            heading={t("integrations.rest.create-credentials-heading", "Create New Credentials")}
            className="slds-card_boundary slds-p-horizontal--x-small"
            icon={<Icon category="utility" name="record_create" size="small"/>}
        >

            <Formik initialValues={{
                name: t("integrations.rest.api-credentials-heading", "API Credentials"),
                roles: [],
            }}
                    validate={(values) => {
                        let errors = {};
                        if (!values.name) {
                            errors.name = t("integrations.rest.validation.name-not-empty", "Name must not be empty");
                        }
                        return errors;
                    }}
                    enableReinitialize={true}
                    onSubmit={(values, actions) => {
                        createTokenMutation({
                            variables: {
                                apiToken: {
                                    appId: app.id,
                                    organisationId: orgId,
                                    name: values.name,
                                    roles: values.roles || [],
                                    generatePassword: values.type === "basic-auth",
                                }
                            }
                        })
                            .catch((err) => {
                                Log.Error("Failed to create token", err);
                                notify.error(t("integrations.rest.notify.create-token-failed", "Failed to create token"));
                            })
                            .finally(() => {
                                actions.setSubmitting(false);
                            });
                    }}
            >{(formik) => {
                return <Form className="slds-m-bottom--medium">
                    <SldsInputField className="slds-m-top--small" name={"name"}
                                    label={t("integrations.rest.name", "Name")}/>
                    <SldsSelectField label={t("integrations.rest.type", "Type")} name={"type"} options={[
                        {label: "Bearer Token", value: "bearer"},
                        {label: "BasicAuth", value: "basic-auth"},
                    ]}/>
                    <PillContainerField name={"roles"} label={t("integrations.rest.roles", "Roles")}
                                        pillLabelExtractor={(it) => getRole(it).displayName}
                                        renderLookup={() => {
                                            return <Lookup
                                                placeholder={t("integrations.rest.placeholder.add-role", "Add Role ...")}
                                                iconExtractor={() => <Icon category="standard" name="user_role"
                                                                           size="small"/>}
                                                titleExtractor={(r) => r.name}
                                                subtitleExtractor={() => undefined}
                                                valueExtractor={(option) => option.name}
                                                onLookup={(value) => {
                                                    let list = formik.values["roles"] || [];
                                                    formik.setFieldValue("roles", insert(list, list.length, value));
                                                }}
                                                loadSuggestions={(value, formik) => {
                                                    Log.Debug("Suggestions", value, formik);
                                                    const search = value.toLowerCase();
                                                    return ROLES.map((t) => {
                                                        const displayName = getRole(t.type).displayName.toLowerCase();
                                                        const name = t.name.toLowerCase();

                                                        if (name.includes(search) || displayName.includes(search)) {
                                                            return t;
                                                        }
                                                    })
                                                        .filter(it => !!it)
                                                        .filter(item => {
                                                            let roles = formik.values.roles || [];
                                                            return roles.findIndex(it => item.name === it) === -1;
                                                        });
                                                }}

                                            />;
                                        }}/>
                    <FormActions>
                        <Button
                            type="submit">{t("integrations.rest.create-credentials", "Create New Credentials")}</Button>
                    </FormActions>
                </Form>;
            }}
            </Formik>
        </Card>

    </div>;
}

RestApiTab.propTypes = {};