import {decodeToken} from "../../common/context/authContext";
import {useMutation, useQuery} from "@apollo/client";
import {useGraphqlLoadingComponent} from "../../common/graphql";
import * as log from "../../common/log";
import {Log} from "../../common/log";

import {Button, Card, Icon} from "@salesforce/design-system-react";
import {
    FormActions,
    SldsFormElementCompound,
    SldsFormElementRow,
    SldsInput,
    SldsInputField,
    SldsSelectField,
    SldsTextarea
} from "../../common/ui/form/formElements";
import {Form, Formik} from "formik";
import * as React from "react";
import gql from "graphql-tag";
import Page from "../../common/ui/page";
import {Link} from "react-router-dom";


const QUERY_API_TOKENS = gql`
    query apiTokens {
        apiTokenList {
            id
            appId
            organisationId
            name
            token
            password
        }
    }
`;

const refetchApiTokens = () => ({
    query: QUERY_API_TOKENS
});


const MUTATION_CREATE_API_TOKEN = gql`
    mutation createToken($apiToken: ApiTokenInput!) {
        createApiToken(input: $apiToken) {
            id
            name
            token
            organisationId
        }
    }
`;


const MUTATION_DELETE_API_TOKEN = gql`
    mutation createToken($tokenId: ID!) {
        deleteApiToken(id: $tokenId) {
            id
            name
            token
        }
    }
`;

export const AdminTokenPage = () => {
    const apiTokensResult = useQuery(QUERY_API_TOKENS);

    const [createTokenMutation] = useMutation(MUTATION_CREATE_API_TOKEN, {
        refetchQueries: [refetchApiTokens()],
    });
    const [deleteTokenMutation] = useMutation(MUTATION_DELETE_API_TOKEN, {
        refetchQueries: [refetchApiTokens()],
    });

    const loading = useGraphqlLoadingComponent(apiTokensResult);
    if (loading) {
        return loading;
    }

    let {data} = apiTokensResult;


    let apiTokens = data.apiTokenList;
    apiTokens = apiTokens.map((t) => {
        return {
            ...t,
            tokenParsed: decodeToken(t.token),
        };
    });

    //only admin tokens relevant
    apiTokens = apiTokens.filter(token => {
        if (token.tokenParsed?.roles){
         return token.tokenParsed.roles.includes("admin")
        }
        return false
    })

    function deleteToken(t) {

        if (!window.confirm(`Delete API Token '${t.name}' [${t.id}]?`)) {
            return;
        }

        deleteTokenMutation({
            variables: {
                tokenId: t.id
            }
        }).catch((err) => {
            log.Error("Failed to delete api token:", err);
            alert("Failed to delete api token.");
        });
    }


    return <Page trail={[<Link to={"./"} key={1}>Administration Tokens</Link>]} title={"Administration Tokens"}>
    <div className="slds-m-horizontal--x-small">
        <div className="adminTokenDoc">
            Tokens for the Platform Administration. Part of the REST API provided by the backend. Used for:
            <ul className="slds-list--dotted">
                <li>Create Organisations</li>
                <li>Delete Organisations</li>
                <li>Create MQTT Endpoints</li>
                <li>Delete MQTT Endpoints</li>
            </ul>
            It is recommended to use Tokens over Basic Auth.<br/>
            Always treat Credentials with great care! Leaked admin tokens/credentials can be misused by attackers.
        </div>
        <div className="slds-text-heading--medium">Admin API Credentials</div>
        {
            apiTokens.map((t) => {
                return <Card key={t.id}
                             heading={t.name}
                             className="slds-card_boundary slds-p-horizontal--x-small"
                             icon={<Icon category="standard" name="data_integration_hub" size="small"/>}
                             headerActions={<div><Button variant="destructive" onClick={() => deleteToken(t)}>Delete</Button></div>}
                >
                    { // When a password is set, show only BasicAuth credentials, else show the token
                        t.password ?
                            <div className="slds-m-bottom--x-small">
                                <SldsFormElementCompound label={"BasicAuth"}>
                                    <SldsFormElementRow>
                                        <SldsInput label="Username" field={{value: "token-" + t.id}}/>
                                        <SldsInput label="Password" field={{value: t.password}}/>
                                        <SldsInput label="BasicAuth Header" field={{value: "Basic " + btoa("token-" + t.id + ":" + t.password)}}/>
                                    </SldsFormElementRow>
                                </SldsFormElementCompound>
                            </div>
                            :
                            <SldsTextarea label={"Authorization Header"} rows={3} field={{value: "Bearer " + t.token}}/>
                    }


                    {/*<Json json={t.tokenParsed}/>*/}
                </Card>;
            })
        }
        <Card
            heading={"Create New Credentials"}
            className="slds-card_boundary slds-p-horizontal--x-small"
            icon={<Icon category="utility" name="record_create" size="small"/>}
        >

            <Formik initialValues={{
                name: "API Admin Credentials",
            }}
                    validate={(values) => {
                        let errors = {};
                        if (!values.name) {
                            errors.name = "Name must not be empty";
                        }
                        return errors;
                    }}
                    enableReinitialize={true}
                    onSubmit={(values, actions) => {
                        createTokenMutation({
                            variables: {
                                apiToken: {
                                    name: values.name,
                                    roles: ["admin"],
                                    generatePassword: values.type === "basic-auth",
                                }
                            }
                        })
                            .catch((err) => {
                                Log.Error("Failed to create token", err);
                                alert("Failed to create token");
                            })
                            .finally(() => {
                                actions.setSubmitting(false);
                            });
                    }}
            >{() => {
                return <Form className="slds-m-bottom--medium">
                    <SldsInputField className="slds-m-top--small" name={"name"} label={"Name"}/>
                    <SldsSelectField label={"Type"} name={"type"} options={[
                        {label: "Bearer Token", value: "bearer"},
                        {label: "BasicAuth", value: "basic-auth"},
                    ]}/>
                    <FormActions>
                        <Button type="submit">Create New Credentials</Button>
                    </FormActions>
                </Form>;
            }}
            </Formik>
        </Card>
    </div>
    </Page>
}
